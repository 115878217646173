/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import nexo from '../../nexoClient';
import tokens from '@nimbus-ds/tokens/dist/js/tokens';
import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Card,
  Box,
  Button,
  Select,
  Label,
  Checkbox,
  useToast,
  Tag,
  Text,
  Title,
  Modal,
  IconButton,
  Icon,
  Link,
  Tooltip,
} from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';

import axiosInstance from '../../axios';
import { useWindowWidth } from '../../hooks';
import { CogIcon, QuestionCircleIcon } from '@nimbus-ds/icons';

const ConfigPage: React.FC = () => {
  const { push } = useHistory();
  const windowWidth = useWindowWidth();
  const breakpointMd = tokens.breakpoint.md.value.replace('px', '');
  const { addToast } = useToast();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen2, setModalOpen2] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [warehouses, setWareHouses] = useState<Warehouse[]>([]);
  const [inventories, setInventories] = useState<Inventory[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
  const [paymentsTn, setPaymentsTn] = useState([]);
  const [paymentsSiigo, setPaymentsSiigo] = useState<PaymentSiigo[]>([]);
  const [taxes, setTaxes] = useState<Tax[]>([]);
  const [warehouseSKU, setWarehouseSKU] = useState<Warehouse>();
  //let warehouse_id_stock: number | null = null;
  //let warehouse_id_sync_products: number | null = null;
  const [w, setW] = useState();
  const [w2, setW2] = useState();
  let sku_stock: string | null = '';
  let siigo_code: string | null = '';
  let siigo_shipping_item_code: string | null = '';
  let siigo_shipping_item_name: string | null = '';

  const [siigoShippingItemCode, setSiigoShippingItemCode] = useState<
    string | null
  >();
  const [siigoShippingItemName, setSiigoShippingItemName] = useState<
    string | null
  >();

  const [warehouseSyncProducts, setWarehouseSyncProducts] =
    useState<Warehouse>();
  const [inventorySyncProducts, setInventorySyncProducts] =
    useState<Inventory>();

  /* config orders */
  const [configPayments, setConfigPayments] = useState<ConfigPayments[]>([]);
  const [dian, setDian] = useState(false);
  const [email, setEmail] = useState(false);
  const [includeShippingCostInvoice, setIncludeShippingCostInvoice] =
    useState(false);
  const [generateOrderOn, setGenerateOrderOn] = useState<string | null>();
  const [orderIsActive, setOrderIsActive] = useState(false);
  const [flagSyncSiigoToTn, setFlagSyncSiigoToTn] = useState(false);
  const [flagSyncTnToSiigo, setFlagSyncTnToSiigo] = useState(false);
  const [flagDian, setFlagDian] = useState(false);
  const [flagEmail, setFlagEmail] = useState(false);
  const [buttonDisabledSyncStockUnitary, setButtonDisabledSyncStockUnitary] =
    useState(false);
  const [configTax, setConfigTax] = useState<Tax>();
  const [configDocument, setConfigDocument] = useState<Document>();
  const [configCostCenter, setConfigCostCenter] = useState<CostCenter>();
  const [configPayment, setConfigPayment] = useState<ConfigPayments>();

  /* config products sync Siigo to TN */
  const [showMessageSync, setShowMessageSync] = useState(false);
  const [inventory, setInventory] = useState<Inventory>();
  /* config products sync TN to Siigo */
  const [showMessageSync2, setShowMessageSync2] = useState(false);
  const [inventory2, setInventory2] = useState<Inventory>();
  const [taxSync, setTaxForSync] = useState<Tax>();
  /* config for sync stock automation */
  const [inventorySyncStock, setInventorySyncStock] = useState<Inventory>();
  const [warehouseSyncStock, setWarehouseSyncStock] = useState<Warehouse>();
  //const [inventory3, setInventory3] = useState<Inventory>();
  /* config for unitary sync stock */
  const [inventorySyncStockUnitary, setInventorySyncStockUnitary] =
    useState<Inventory>();

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  const goToLogs = () => push('/logs');
  const goToSetup = () => push('/setup');

  interface Warehouse {
    warehouse_id: string;
    name: string;
  }

  interface Inventory {
    inventory_id: string;
    name: string;
  }

  interface Tax {
    tax_id: string;
    name: string;
  }

  interface PaymentTn {
    payment_tn_id: string;
    name: string;
  }

  interface PaymentSiigo {
    payment_siigo_id: string;
    name: string;
  }

  interface Document {
    document_siigo_id: string;
    document_siigo_code: string;
    name: string;
  }

  interface CostCenter {
    cost_center_id: string;
    code: string;
    name: string;
  }

  interface ConfigPayments {
    payment_tn_id: string;
    payment_siigo_id: string;
    name: string;
  }

  useEffect(() => {
    getConfig();
  }, []);

  const syncClients = async () => {
    console.log('sync clientes call');
    await axiosInstance
      .post('/sync_clients', {
        bodega: '',
      })
      .then((response) => {
        console.log(response);
        addToast({
          id: 'sync-clients-toast-success',
          type: 'success',
          text: 'Sincronizando Clientes...',
          duration: 4000,
        });
      })
      .catch((e) => {
        addToast({
          id: 'sync-clientes-toast-fail',
          type: 'danger',
          text:
            e.response.data?.message ?? 'Error intentando sincronizar clientes',
          duration: 4000,
        });
        console.log(e);
      });

    return;
  };

  const onModalSyncClose = async () => {
    console.log('on modal sync close call');
    setModalOpen(false);
    console.log('is modal open? ', isModalOpen);
  };

  const onModalSyncClose2 = async () => {
    console.log('on modal sync close call');
    setModalOpen2(false);
    console.log('is modal open? ', isModalOpen2);
  };

  const onButtonDisabled = async () => {
    console.log('on button disabled');
    setButtonDisabled(true);
  };

  const onShowMessageSync = async () => {
    setShowMessageSync(true);
  };

  const onShowMessageSync2 = async () => {
    setShowMessageSync2(true);
  };

  const handleChangeDianCheckbox = (e: any) => {
    console.log(e.target.value);
    console.log(e.target.checked);
    setDian(e.target.checked);
  };

  const handleChangeEmailCheckbox = (e: any) => {
    console.log(e.target.value);
    console.log(e.target.checked);
    setEmail(e.target.checked);
  };

  const handleChangeIncludeShippingCostInvoice = (e: any) => {
    console.log(e.target.value);
    console.log(e.target.checked);
    setIncludeShippingCostInvoice(e.target.checked);
  };

  /*
  const handleChangeBodegaSyncProducts = (e: any) => {
    warehouse_id_sync_products = e.target.value ?? null;
    console.log(warehouse_id_sync_products);
    setW(e.target.value ?? null);
  };

  const handleChangeBodegaSyncProducts2 = (e: any) => {
    console.log('handle change bodega sync 2');
    console.log(e.target.value);
    setW2(e.target.value ?? null);
  };

   const handleChangeBodegaStock = (e: any) => {
    console.log(e.target.value);
    warehouse_id_stock = e.target.value;
  };
  */

  const handleChangeInventorySyncStockUnitary = (e: any) => {
    console.log('handle change inventory sync stock unitary: ', e.target.value);
    for (const inventory of inventories) {
      if (inventory.inventory_id == e.target.value) {
        console.log('inventory found');
        const inventory_opts: Inventory = {
          inventory_id: e.target.value,
          name: inventory.name,
        };
        setInventorySyncStockUnitary(inventory_opts);
      }
    }
  };

  const handleChangeInventorySyncProducts = (e: any) => {
    console.log('handle change inventory sync 1', e.target.value);
    for (const inventory of inventories) {
      if (inventory.inventory_id == e.target.value) {
        console.log('inventory found');
        const inventory_opts: Inventory = {
          inventory_id: e.target.value,
          name: inventory.name,
        };
        setInventory(inventory_opts);
      }
    }
  };

  const handleChangeInventorySyncProducts2 = (e: any) => {
    console.log('handle change inventory sync 2 ', e.target.value);
    for (const inventory of inventories) {
      if (inventory.inventory_id == e.target.value) {
        console.log('inventory found');
        const inventory_opts: Inventory = {
          inventory_id: e.target.value,
          name: inventory.name,
        };
        setInventory2(inventory_opts);
      }
    }
  };

  const handleChangeInventorySyncStock = (e: any) => {
    console.log('handle change inventory sync stock', e.target.value);
    if (e.target.value == 'Seleccionar inventario') {
      console.log('option default found');
      const inventory_opts: Inventory = {
        inventory_id: '0',
        name: 'no-inventory',
      };
      setInventorySyncStock(inventory_opts);
    } else {
      for (const inventory of inventories) {
        if (inventory.inventory_id == e.target.value) {
          console.log('inventory found');
          const inventory_opts: Inventory = {
            inventory_id: e.target.value,
            name: inventory.name,
          };
          setInventorySyncStock(inventory_opts);
        }
      }
    }
  };

  const handleChangeWarehouseSyncStock = (e: any) => {
    console.log('handle change warehouse sync stock', e.target.value);
    if (e.target.value == 'Seleccionar bodega') {
      console.log('option default found');
      const warehouse_opts: Warehouse = {
        warehouse_id: '0',
        name: 'no-warehouse',
      };
      setWarehouseSyncStock(warehouse_opts);
    } else {
      for (const warehouse of warehouses) {
        if (warehouse.warehouse_id == e.target.value) {
          console.log('warehouse found');
          const warehouse_opts: Warehouse = {
            warehouse_id: e.target.value,
            name: warehouse.name,
          };
          setWarehouseSyncStock(warehouse_opts);
        }
      }
    }
  };

  const handleChangeSKU = (e: any) => {
    console.log(e.target.value);
    sku_stock = e.target.value;
  };

  const handleChangeSiigoCode = (e: any) => {
    console.log(e.target.value);
    siigo_code = e.target.value;
  };

  const handleChangeSiigoShippingItem = (e: any) => {
    console.log(e.target.value);
    siigo_shipping_item_code = e.target.value;
  };

  const vinculateShippingItem = async () => {
    console.log(
      'vinculate shipping item call, item found ? ',
      siigo_shipping_item_code,
    );
    if (!siigo_shipping_item_code) {
      addToast({
        id: 'vinculate-shipping-item-toast-warning',
        type: 'danger',
        text: 'Ingrese un Código para vincular item',
        duration: 4000,
      });
      return;
    }
    await axiosInstance
      .post('/vinculate_shipping_item', {
        shipping_item_code: siigo_shipping_item_code ?? null,
      })
      .then((response) => {
        if (response.data?.status == true) {
          setSiigoShippingItemCode(response.data.shipping_item_code);
          setSiigoShippingItemName(response.data.shipping_item_name);
          addToast({
            id: 'vinculate-shipping-item-toast-success',
            type: 'success',
            text: 'El item ha sido vinculado exitosamente',
            duration: 4000,
          });
        } else {
          addToast({
            id: 'vinculate-shipping-item-toast-fail',
            type: 'danger',
            text: response.data?.message ?? 'No se ha podido vincular el item',
            duration: 4000,
          });
        }
      })
      .catch((e) => {
        addToast({
          id: 'vinculate-shipping-item-toast-fail',
          type: 'danger',
          text: e.response.data?.message ?? 'No se ha podido vincular el item',
          duration: 4000,
        });
        console.log(e);
      });

    return;
  };

  const handleConfigTax = (e: any) => {
    console.log(
      'handle config tax call, values: ',
      e.target.label,
      e.id,
      e.target.key,
      e.target.value,
    );
    for (const tax of taxes) {
      if (tax.tax_id == e.target.value) {
        console.log('tax found');
        const config_tax: Tax = {
          tax_id: e.target.value,
          name: tax.name,
        };
        setConfigTax(config_tax);
      }
    }
    console.log('config tax now: ', configTax);
  };

  const handleConfigCostCenter = (e: any) => {
    console.log(
      'handle config cost center call, values: ',
      e.target.label,
      e.id,
      e.target.key,
      e.target.value,
    );
    for (const constCenter of costCenters) {
      if (constCenter.cost_center_id == e.target.value) {
        console.log('cost center found');
        const config_cost_center: CostCenter = {
          cost_center_id: e.target.value,
          code: '',
          name: constCenter.name,
        };
        setConfigCostCenter(config_cost_center);
      }
    }
    console.log('config center now: ', configCostCenter);
  };

  const handleConfigDocument = (e: any) => {
    console.log(
      'handle config document call, values: ',
      e.target.label,
      e.id,
      e.target.key,
      e.target.value,
    );
    for (const document of documents) {
      if (document.document_siigo_id == e.target.value) {
        console.log('document found');
        const config_document: Document = {
          document_siigo_id: e.target.value,
          document_siigo_code: '',
          name: document.name,
        };
        setConfigDocument(config_document);
      }
    }
    console.log('config tax now: ', configTax);
  };

  const handleConfigTaxSyncProduct = (e: any) => {
    console.log(
      'handle config tax sync product call, value: ',
      e.target.label,
      e.id,
      e.target.key,
      e.target.value,
    );
    for (const tax of taxes) {
      if (tax.tax_id == e.target.value) {
        console.log('tax found');
        const config_tax: Tax = {
          tax_id: e.target.value,
          name: tax.name,
        };
        setTaxForSync(config_tax);
      }
    }
    console.log('config tax for product now: ', configTax);
  };

  const handleGenerateOrderOn = (e: any) => {
    setGenerateOrderOn(e.target.value);
  };

  const confirmSyncProducts = async () => {
    console.log(
      'confirm sync products (Siigo to TN) inventory found? ,',
      inventory,
    );
    if (!inventory) {
      addToast({
        id: 'sync-products-toast-warning',
        type: 'danger',
        text: 'Ingrese una iventario para actualizar el catálogo de Siigo a Tiendanube.',
        duration: 4000,
      });
      return;
    }

    setModalOpen(true);

    /*
    const warehouse = w;

    console.log('confirm sync products sku found? ,', warehouse);
    if (!warehouse) {
      addToast({
        id: 'sync-products-toast-warning',
        type: 'danger',
        text: 'Ingrese una bodega para actualizar el catálogo.',
        duration: 4000,
      });
      return;
    }
    setModalOpen(true);
    */
  };

  const confirmSyncProducts2 = async () => {
    const inventory = inventory2;
    const tax = taxSync;

    console.log(
      'confirm sync products (Tn a Siigo) inventory found? ,',
      inventory,
    );
    console.log('confirm sync products (Tn a Siigo) tax found? ,', tax);
    if (!inventory) {
      addToast({
        id: 'sync-products2-toast-warning',
        type: 'danger',
        text: 'Ingrese una iventario para actualizar el catálogo de Tiendanube a Siigo.',
        duration: 4000,
      });
      return;
    }
    if (!tax) {
      addToast({
        id: 'sync-products2-tax-toast-warning',
        type: 'danger',
        text: 'Ingrese una impuesto asociado para actualizar el catálogo de Tiendanube a Siigo.',
        duration: 4000,
      });
      return;
    }
    setModalOpen2(true);
  };

  const syncProducts = async () => {
    console.log('sync products call');
    //console.log(warehouse_id_sync_products);
    if (!inventory) {
      addToast({
        id: 'update-sku-toast-warning',
        type: 'danger',
        text: 'Ingrese un inventario para actualizar el catálogo',
        duration: 4000,
      });
      return;
    }
    //return;
    console.log('sync products call with ', inventory);
    await axiosInstance
      .post('/sync_products', {
        inventario: inventory,
      })
      .then((response) => {
        console.log(response);
        console.log('on modal sync close?');
        onModalSyncClose();
        onButtonDisabled();
        onShowMessageSync();
        addToast({
          id: 'update-sku-toast-success',
          type: 'success',
          text: 'Sincronizando Productos. La tarea se procesará en segundo plano y puede demorar varios minutos.',
          duration: 8000,
        });
      })
      .catch((e) => {
        addToast({
          id: 'update-sku-toast-fail',
          type: 'danger',
          text:
            e.response.data?.message ??
            'Error intentando sincronizar productos',
          duration: 4000,
        });
        console.log(e);
      });

    return;
  };

  const syncProducts2 = async () => {
    console.log('sync products 2 call');
    //console.log(warehouse_id_sync_products);
    const inventory = inventory2;
    const tax = taxSync;
    console.log('warehouse apply: ', inventory);
    console.log('tax apply:', tax);
    if (!inventory) {
      addToast({
        id: 'update-p2-warehouse-toast-warning',
        type: 'danger',
        text: 'Ingrese un inventario para actualizar el catálogo',
        duration: 4000,
      });
      return;
    }
    if (!tax) {
      addToast({
        id: 'update-p2-tax-toast-warning',
        type: 'danger',
        text: 'Ingrese un impuesto para actualizar el catálogo',
        duration: 4000,
      });
      return;
    }
    //return;
    console.log('sync products 2 call with ', inventory, tax);

    await axiosInstance
      .post('/sync_products_tn', {
        inventario: inventory,
        impuesto: tax,
      })
      .then((response) => {
        console.log(response);
        console.log('on modal sync close?');
        onModalSyncClose2();
        onButtonDisabled();
        onShowMessageSync2();
        addToast({
          id: 'update-sku-toast-success',
          type: 'success',
          text: 'Sincronizando Productos. La tarea se procesará en segundo plano y puede demorar varios minutos.',
          duration: 8000,
        });
      })
      .catch((e) => {
        addToast({
          id: 'update-sku-toast-fail',
          type: 'danger',
          text:
            e.response.data?.message ??
            'Error intentando sincronizar productos',
          duration: 4000,
        });
        console.log(e);
      });

    return;
  };

  const createProduct = async () => {
    console.log('create unitary product call');
    if (!siigo_code) {
      addToast({
        id: 'create-unitary-product-toast-warning',
        type: 'danger',
        text: 'Ingrese un Código',
        duration: 4000,
      });
      return;
    }
    await axiosInstance
      .post('/create_product_unitary', {
        code: siigo_code ?? null,
      })
      .then((response) => {
        console.log(response);
        addToast({
          id: 'create-unitary-product-toast-success',
          type: 'success',
          text: 'Creando producto... ',
          duration: 4000,
        });
      })
      .catch((e) => {
        addToast({
          id: 'create-unitary-product-toast-fail',
          type: 'danger',
          text:
            e.response.data?.message ?? 'Error al crear producto en Tiendanube',
          duration: 4000,
        });
        console.log(e);
      });

    return;
  };

  const updateStockSKU = async () => {
    console.log('update stock SKU call');
    setButtonDisabledSyncStockUnitary(true);
    if (!sku_stock) {
      addToast({
        id: 'update-sku-toast-warning',
        type: 'danger',
        text: 'Ingrese un SKU',
        duration: 4000,
      });
      setButtonDisabledSyncStockUnitary(false);
      return;
    }
    console.log(inventorySyncStockUnitary);
    if (!inventorySyncStockUnitary) {
      addToast({
        id: 'update-sku-unitary-toast-warning',
        type: 'danger',
        text: 'Ingrese un inventario para actualizar SKU',
        duration: 4000,
      });
      setButtonDisabledSyncStockUnitary(false);
      return;
    }
    await axiosInstance
      .post('/sync_stock_unitary', {
        sku: sku_stock ?? null,
        inventario: inventorySyncStockUnitary.inventory_id ?? null,
      })
      .then((response) => {
        console.log(response);
        addToast({
          id: 'update-sku-unitary-toast-success',
          type: 'success',
          text: 'Actualizando SKU... ',
          duration: 4000,
        });
        setButtonDisabledSyncStockUnitary(false);
      })
      .catch((e) => {
        addToast({
          id: 'update-sku-unitary-toast-fail',
          type: 'danger',
          text: e.response.data?.message ?? 'Error al actualizar SKU',
          duration: 4000,
        });
        console.log(e);
        setButtonDisabledSyncStockUnitary(false);
      });

    return;
  };

  const getConfig = async () => {
    console.log('get config app call');

    await axiosInstance
      .get('/config_app')
      .then((config) => {
        if (config && config.data.status == true) {
          const data = config.data;
          const warehouses = data.warehouses;
          const inventories = data.inventories;
          const taxes = data.taxes;
          const tn_payments = data.payments;
          const siigo_payments = data.siigo_payments;
          const documents = data.documents;
          const cost_centers = data.cost_centers;
          const config_document = data.document;
          const config_cost_center = data.cost_center;
          const config_payments = data.config_payments;
          const tax = data.impuesto;
          const dian_config = data.dian;
          const email_config = data.email;
          const shipping_cost_invoice = data.shipping_cost_invoice;
          const generate_order_on = data.generate_order_on;
          const inventory_sync_stock = data.inventory_sync_stock;
          const warehouse_sync_stock = data.warehouse_sync_stock;
          const order_is_active = data.order_is_active;
          const shipping_item_code = data.shipping_item_code;
          const shipping_item_name = data.shipping_item_name;
          const flag_sync_tn_to_siigo = data.flag_sync_tn_to_siigo;
          const flag_sync_siigo_to_tn = data.flag_sync_siigo_to_tn;
          const flag_dian = data.flag_dian;
          const flag_email = data.flag_email;

          //load info
          if (warehouses && warehouses.length > 0) {
            console.log('warehouses updated');
            setWareHouses(warehouses);
          }
          if (inventories && inventories.length > 0) {
            console.log('inventories updated');
            setInventories(inventories);
          }
          if (taxes && taxes.length > 0) {
            console.log('taxes updated');
            setTaxes(taxes);
          }
          if (documents && documents.length > 0) {
            console.log('documents updated');
            setDocuments(documents);
          }
          if (cost_centers && cost_centers.length > 0) {
            console.log('cost centers updated');
            setCostCenters(cost_centers);
          }
          if (tn_payments && tn_payments.length > 0) {
            setPaymentsTn(tn_payments);
            console.log('payments updated');
            if (siigo_payments && siigo_payments.length > 0) {
              setPaymentsSiigo(siigo_payments);
            }
          }
          if (config_payments && config_payments.length > 0) {
            console.log('config payments found');
            console.log(config_payments);
            const configPayment = [];
            for (const c of config_payments) {
              const config_payment: ConfigPayments = {
                payment_tn_id: c.payment_tn_id,
                payment_siigo_id: String(c.payment_siigo_id),
                name: c.name,
              };
              configPayment.push(config_payment);
            }
            console.log('set config payments', configPayment);
            setConfigPayments(configPayment);
          }
          if (dian_config) {
            console.log('dian found ', dian_config);
            setDian(dian_config);
          }
          if (email_config) {
            console.log('email customer found ', email_config);
            setEmail(email_config);
          }
          if (shipping_cost_invoice) {
            console.log('shipping const invoice found ', shipping_cost_invoice);
            setIncludeShippingCostInvoice(shipping_cost_invoice);
          }
          if (config_document) {
            console.log('config document found ', config_document);
            for (const d of documents) {
              if (d.document_siigo_id == config_document) {
                console.log('document found to associate');
                const document_selected = {
                  document_siigo_id: d.document_siigo_id,
                  document_siigo_code: d.document_siigo_code,
                  name: d.name,
                };
                console.log('data to associate ', document_selected);
                setConfigDocument(document_selected);
              }
            }
          }
          if (config_cost_center) {
            console.log('config cost center found ', config_cost_center);
            for (const c of cost_centers) {
              if (c.cost_center_id == config_cost_center) {
                console.log('cost center found to associate');
                const cost_center_selected = {
                  cost_center_id: c.cost_center_id,
                  code: c.code,
                  name: c.name,
                };
                console.log('data to associate ', cost_center_selected);
                setConfigCostCenter(cost_center_selected);
              }
            }
          }
          if (generate_order_on) {
            console.log('generate order on found ', generate_order_on);
            setGenerateOrderOn(generate_order_on ?? null);
          }
          if (tax) {
            console.log('tax found ', tax);
            for (const t of taxes) {
              if (t.tax_id == tax) {
                console.log('tax found');
                const tax_selected = {
                  tax_id: t.tax_id,
                  name: t.name,
                };
                console.log('tax selected ', tax_selected);
                setConfigTax(tax_selected);
              }
            }
          }
          if (inventory_sync_stock) {
            console.log('inventory sync stock found ', inventory_sync_stock);
            //inventorySyncStock
            for (const inventory of inventories) {
              if (inventory.inventory_id == inventory_sync_stock) {
                console.log('inventory found');
                const inventory_selected = {
                  inventory_id: String(inventory.inventory_id),
                  name: String(inventory.name),
                };
                setInventorySyncStock(inventory_selected);
              }
            }
          }

          if (warehouse_sync_stock) {
            console.log('warehouse sync stock found ', warehouse_sync_stock);
            //warehouseSyncStock
            for (const warehouse of warehouses) {
              if (warehouse.warehouse_id == warehouse_sync_stock) {
                console.log('warehouse found');
                const warehouse_selected = {
                  warehouse_id: String(warehouse.warehouse_id),
                  name: String(warehouse.name),
                };
                setWarehouseSyncStock(warehouse_selected);
              }
            }
          }

          //shipping item
          siigo_shipping_item_code = shipping_item_code;
          siigo_shipping_item_name = shipping_item_name;

          setSiigoShippingItemCode(shipping_item_code);
          setSiigoShippingItemName(shipping_item_name);

          //order is active flag
          setOrderIsActive(order_is_active);

          //set sync flags
          setFlagSyncSiigoToTn(flag_sync_siigo_to_tn);
          setFlagSyncTnToSiigo(flag_sync_tn_to_siigo);
          //set checkbox flags
          setFlagDian(flag_dian);
          setFlagEmail(flag_email);

          return true;
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.response?.data?.error_code == 'APP_VALIDATION_PENDING') {
          goToSetup();
        }
        if (e.response?.data?.error_code == 'NO_APP_FOUND') {
          //TODO: DISPLAY MODAL ERROR MESSAGE NO APP FOUND
        }
      });

    return;
  };

  const refreshPayments = async () => {
    console.log('refresh payments call');
    await axiosInstance
      .post('/refresh_payments')
      .then((response) => {
        console.log(response);
        addToast({
          id: 'refresh-payments-toast-success',
          type: 'success',
          text: 'Medios de pago actualizados',
          duration: 4000,
        });
      })
      .catch((e) => {
        addToast({
          id: 'refresh-payments-toast-error',
          type: 'danger',
          text:
            e.response.data?.message ??
            'No se pudieron actualizar los medios de pago',
          duration: 4000,
        });
        console.log(e);
      });

    return;
  };

  const handleConfigPayments = (e: any) => {
    console.log(e.target.id, e.target.value, e.target.name);

    const payment = paymentsSiigo.find(
      (p) => p.payment_siigo_id == String(e.target.value),
    );
    console.log(payment);
    if (payment) {
      console.log('payment found');
      console.log(payment);
      const config_payment: ConfigPayments = {
        payment_tn_id: String(e.target.id),
        payment_siigo_id: String(e.target.value),
        name: payment.name,
      };
      console.log(config_payment);
      const filteredArray = configPayments.filter(
        (f) => String(f.payment_tn_id) !== String(e.target.id),
      );
      console.log('filtered array', filteredArray);
      filteredArray.push(config_payment);
      console.log('filterarray with push ', filteredArray);
      setConfigPayments(filteredArray);
    } else {
      const paymentTn = configPayments.find(
        (p) => p.payment_tn_id == String(e.target.id),
      );
      if (paymentTn) {
        paymentTn.payment_siigo_id = '';
        console.log('new config payments updated: ', configPayments);
      }
    }
  };

  const saveConfigOrders = async () => {
    console.log('save config orders call');

    console.log('config payments to send:', configPayments);
    console.log('config document to send', configDocument);
    console.log('config cost center to send', configCostCenter);
    console.log('config process order on to send', generateOrderOn);
    console.log(
      'include shipping const invoice to send',
      includeShippingCostInvoice,
    );

    await axiosInstance
      .post('/config_orders', {
        impuesto: configTax?.tax_id ?? 1270,
        documento: configDocument?.document_siigo_id ?? null,
        cost_center: configCostCenter?.cost_center_id ?? null,
        dian: dian,
        email: email,
        shipping_cost_invoice: includeShippingCostInvoice,
        generate_order_on: generateOrderOn ?? null,
        payments_config: configPayments,
      })
      .then((response) => {
        console.log(response);
        if (response.data.order_is_active) {
          console.log('order is active found');
          setOrderIsActive(response.data.order_is_active);
        }
        addToast({
          id: 'update-config-orders-toast-success',
          type: 'success',
          text: 'Configuraciones actualizadas ',
          duration: 4000,
        });
      })
      .catch((e) => {
        addToast({
          id: 'update-config-orders-toast-error',
          type: 'danger',
          text:
            e.response.data?.message ??
            'No se pudo actualizar la configuración',
          duration: 4000,
        });
        console.log(e);
      });

    return;
  };

  const saveSyncStocksConfig = async () => {
    console.log('save sync stock config call');

    console.log('config sync stock to send:');
    console.log(inventorySyncStock, warehouseSyncStock);

    if (!inventorySyncStock && !warehouseSyncStock) {
      addToast({
        id: 'inventory-sync-stock-toast-warning',
        type: 'danger',
        text: 'Ingrese un inventario o una bodega',
        duration: 4000,
      });
      return;
    }

    await axiosInstance
      .post('/config_sync_stock', {
        inventario: inventorySyncStock?.inventory_id ?? null,
        warehouse: warehouseSyncStock?.warehouse_id ?? null,
      })
      .then((response) => {
        console.log(response);
        addToast({
          id: 'inventory-sync-stock-toast-success',
          type: 'success',
          text: 'Configuración actualizada ',
          duration: 4000,
        });
      })
      .catch((e) => {
        addToast({
          id: 'inventory-sync-stock-toast-error',
          type: 'danger',
          text:
            e.response.data?.message ??
            'No se pudo actualizar la configuración',
          duration: 4000,
        });
        console.log(e);
      });

    return;
  };

  const refreshAllSiigoData = async () => {
    console.log('refresh all siigo data call');

    await axiosInstance
      .post('/refresh_siigo_data', {})
      .then((response) => {
        console.log(response);
        addToast({
          id: 'refresh-siigo-data-toast-success',
          type: 'success',
          text: 'Actualizando datos...',
          duration: 4000,
        });
      })
      .catch((e) => {
        addToast({
          id: 'refresh-siigo-data-toast-error',
          type: 'danger',
          text:
            e.response.data?.message ?? 'No se han podido actualizar los datos',
          duration: 4000,
        });
        console.log(e);
      });

    return;
  };

  const actions =
    windowWidth !== null && windowWidth <= breakpointMd ? (
      <IconButton
        backgroundColor="transparent"
        borderColor="transparent"
        onClick={refreshAllSiigoData}
        source={<QuestionCircleIcon />}
        size="2rem"
      />
    ) : (
      <Box display="flex" gap="4">
        <Button appearance="primary">
          <Link
            as="a"
            href="https://scribehow.com/page/Siigo_APP__1nUKzrNARnyp49IRtLHUqQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            VER INSTRUCTIVO
          </Link>
        </Button>
        <Box display="flex" justifyContent="flex-start" gap="2">
          <Button onClick={refreshAllSiigoData} appearance="neutral">
            Actualizar datos
          </Button>
          <Tooltip
            content="En caso de que no encuentres o hayas cargado nuevos
          inventarios ,impuestos, documentos, medios de pago o almacenes en Siigo, pulsa este botón para actualizarlos,
          luego recarga la página para que se vean reflejados."
          >
            {<QuestionCircleIcon />}
          </Tooltip>
        </Box>
        <Box display="flex" justifyContent="flex-start" gap="2">
          <Button onClick={refreshPayments} appearance="neutral">
            Actualizar medios de pago
          </Button>
          <Tooltip content="Actualiza medios de pago desde Tiendanube en caso de que hayas cargado nuevos o eliminado antiguos.">
            {<QuestionCircleIcon />}
          </Tooltip>
        </Box>
      </Box>
    );

  return (
    <Page maxWidth="1300px">
      <Page.Header title="Configuraciones" buttonStack={actions} />
      <Page.Body>
        <Layout columns="1" gap="4">
          <Layout columns="3">
            <Layout.Section>
              <Card>
                <Card.Header title="Enviar productos de Siigo a Tiendanube" />
                <Card.Body>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="4"
                    marginTop="4"
                  >
                    <Text as="span">
                      <strong>
                        {' '}
                        Selecciona el inventario desde donde quieres importar
                        productos
                      </strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="productos-sync-inventory"
                      name="Name"
                      onChange={(event) => {
                        handleChangeInventorySyncProducts(event);
                      }}
                    >
                      <Select.Option
                        disabled
                        label="Seleccionar Inventario"
                        selected
                        value={
                          inventorySyncProducts
                            ? inventorySyncProducts.inventory_id
                            : 'Option default'
                        }
                      />
                      {inventories ? (
                        inventories.map((inventory: Inventory) => (
                          <Select.Option
                            key={inventory.inventory_id}
                            value={inventory.inventory_id}
                            label={inventory.name}
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                    <Box display="flex" justifyContent="center" gap="4">
                      <Button
                        onClick={confirmSyncProducts}
                        appearance="primary"
                        disabled={isButtonDisabled || !flagSyncSiigoToTn}
                      >
                        Sincronizar
                      </Button>
                    </Box>
                    <Box
                      display={showMessageSync ? 'flex' : 'none'}
                      justifyContent="center"
                      gap="4"
                      margin="4"
                    >
                      <Text as="span" color="success-textLow">
                        <strong>
                          Sincronizando productos, luego de unos minutos podrás
                          ver los resultados en la sección de logs.
                        </strong>
                      </Text>
                    </Box>
                  </Box>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header title="Enviar productos de Tiendanube a Siigo" />
                <Card.Body>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="4"
                    marginBottom="4"
                  >
                    <Text as="span">
                      <strong>Impuesto a aplicar</strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="sync-product-select-impuesto"
                      name="Name"
                      onChange={(event) => {
                        handleConfigTaxSyncProduct(event);
                      }}
                    >
                      <Select.Option
                        label="Seleccionar impuesto"
                        selected={true}
                        value="Seleccionar impuesto"
                      />
                      {taxes ? (
                        taxes.map((tax: Tax) => (
                          <Select.Option
                            key={tax.tax_id}
                            value={tax.tax_id}
                            label={tax.name}
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                  </Box>
                  <Checkbox
                    name="control-inventario-checkbox"
                    label="Con control de inventario"
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="4"
                    marginTop="4"
                  >
                    <Text as="span">
                      <strong>
                        {' '}
                        Selecciona el inventario a donde quieres exportar
                        productos
                      </strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="productos-sync-inventory-2"
                      name="Name"
                      onChange={(event) => {
                        handleChangeInventorySyncProducts2(event);
                      }}
                    >
                      <Select.Option
                        disabled
                        label="Seleccionar Inventario"
                        selected
                        value={
                          inventorySyncProducts
                            ? inventorySyncProducts.inventory_id
                            : 'Option default'
                        }
                      />
                      {inventories ? (
                        inventories.map((inventory: Inventory) => (
                          <Select.Option
                            key={inventory.inventory_id}
                            value={inventory.inventory_id}
                            label={inventory.name}
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                    <Box display="flex" justifyContent="center" gap="4">
                      <Button
                        onClick={confirmSyncProducts2}
                        appearance="primary"
                        disabled={isButtonDisabled || !flagSyncTnToSiigo}
                      >
                        Sincronizar
                      </Button>
                    </Box>
                    <Box
                      display={showMessageSync2 ? 'flex' : 'none'}
                      justifyContent="center"
                      gap="4"
                      margin="4"
                    >
                      <Text as="span" color="success-textLow">
                        <strong>
                          Sincronizando productos, luego de unos minutos podrás
                          ver los resultados en la sección de logs.
                        </strong>
                      </Text>
                    </Box>
                  </Box>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header title="Crear producto unitario en Tiendanube" />
                <Card.Body>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="4"
                    marginTop="4"
                  >
                    <Text as="span">
                      <strong>
                        {' '}
                        Ingrese el código del producto de Siigo para crearlo en
                        Tiendanube
                      </strong>
                    </Text>
                    <FormField.Input
                      appendPosition="start"
                      placeholder="Ejemplo: XXX123"
                      onChange={(event) => {
                        handleChangeSiigoCode(event);
                      }}
                    />
                    <Button onClick={createProduct} appearance="primary">
                      Crear producto
                    </Button>
                  </Box>
                </Card.Body>
              </Card>
            </Layout.Section>
            <Layout.Section>
              <Card>
                <Card.Header title="Configuración de facturación" />
                <Card.Body>
                  <Box display="flex" flexDirection="column" gap="4">
                    <Box display="flex" flexDirection="row" gap="2">
                      <Text as="span"> Estado: </Text>
                      <Tag appearance={orderIsActive ? 'primary' : 'danger'}>
                        <Text>{orderIsActive ? 'Activo' : 'Inactivo'}</Text>
                      </Tag>
                    </Box>
                    <Text as="span">
                      <strong>Procesar factura</strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="ordenes-select-envio"
                      name="Name"
                      onChange={(event) => {
                        handleGenerateOrderOn(event);
                      }}
                    >
                      <Select.Option
                        disabled
                        label="Seleccionar"
                        value="Seleccionar"
                        selected={generateOrderOn == null}
                      />
                      <Select.Option
                        label="AL CREAR"
                        value="AL CREAR"
                        selected={generateOrderOn == 'AL CREAR' ? true : false}
                      />
                      <Select.Option
                        label="AL PAGAR"
                        value="AL PAGAR"
                        selected={generateOrderOn == 'AL PAGAR' ? true : false}
                      />
                    </Select>
                    <Text as="span">
                      <strong>Asignar documento Factura</strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="ordenes-select-documento"
                      name="Name"
                      onChange={(event) => {
                        handleConfigDocument(event);
                      }}
                    >
                      <Select.Option
                        disabled
                        label="Seleccionar documento"
                        selected={
                          configDocument?.document_siigo_id ? false : true
                        }
                        value="Seleccionar documento"
                      />
                      {documents ? (
                        documents.map((document: Document) => (
                          <Select.Option
                            key={document.document_siigo_id}
                            value={document.document_siigo_id}
                            label={
                              document.name +
                              ' (código: ' +
                              document.document_siigo_code +
                              ')'
                            }
                            selected={
                              configDocument?.document_siigo_id ==
                              document.document_siigo_id
                                ? true
                                : false
                            }
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                    <Text as="span">
                      <strong>Asignar impuesto</strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="ordenes-select-impuesto"
                      name="Name"
                      onChange={(event) => {
                        handleConfigTax(event);
                      }}
                    >
                      <Select.Option
                        disabled
                        label="Seleccionar impuesto"
                        selected={configTax?.tax_id ? false : true}
                        value="Seleccionar impuesto"
                      />
                      {taxes ? (
                        taxes.map((tax: Tax) => (
                          <Select.Option
                            key={tax.tax_id}
                            value={tax.tax_id}
                            label={tax.name}
                            selected={
                              configTax?.tax_id == tax.tax_id ? true : false
                            }
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                    <Text as="span">
                      <strong>Centro de costo</strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="ordenes-select-cost-center"
                      name="Name"
                      onChange={(event) => {
                        handleConfigCostCenter(event);
                      }}
                    >
                      <Select.Option
                        disabled
                        label="Seleccionar centro"
                        selected={
                          configCostCenter?.cost_center_id ? false : true
                        }
                        value="Seleccionar centro"
                      />
                      {costCenters ? (
                        costCenters.map((costCenter: CostCenter) => (
                          <Select.Option
                            key={costCenter.cost_center_id}
                            value={costCenter.cost_center_id}
                            label={costCenter.name}
                            selected={
                              configCostCenter?.cost_center_id ==
                              costCenter.cost_center_id
                                ? true
                                : false
                            }
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                    <Text as="span">
                      <strong>Enviar factura</strong>
                    </Text>
                    <Checkbox
                      name="dian-checkbox"
                      label="Enviar a la DIAN"
                      checked={dian}
                      disabled={!flagDian}
                      onChange={(event) => {
                        handleChangeDianCheckbox(event);
                      }}
                    />
                    <Checkbox
                      name="email-checkbox"
                      label="Enviar email cliente al crear factura"
                      checked={email}
                      disabled={!flagEmail}
                      onChange={(event) => {
                        handleChangeEmailCheckbox(event);
                      }}
                    />
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="4"
                      marginTop="4"
                    >
                      <Box
                        borderStyle="none"
                        display="flex"
                        flexDirection="row"
                        gap="2"
                      >
                        <Text as="span">
                          <strong>Medio de envío</strong>
                        </Text>
                        <Box maxWidth="200px">
                          <Tooltip
                            content="Configura un producto para envios para asociar a la factura, el cual informará, como un item de producto más, el monto del envío que se le cobró al cliente.
                          Para más información accede al instructivo y visita la sección 'Automatización de facturas electrónicas' "
                          >
                            {<QuestionCircleIcon />}
                          </Tooltip>
                        </Box>
                      </Box>
                      <FormField.Input
                        appendPosition="start"
                        label="Asociar item medio de envío"
                        placeholder="Ejemplo: XXX123"
                        onChange={(event) => {
                          handleChangeSiigoShippingItem(event);
                        }}
                      />
                      <Box display="flex" justifyContent="flex-start" gap="4">
                        <Button
                          onClick={vinculateShippingItem}
                          appearance="neutral"
                        >
                          Asociar
                        </Button>
                        <Box display="flex" flexDirection="column" gap="2">
                          <Text as="span">
                            Estado:{' '}
                            <strong>
                              {siigoShippingItemCode
                                ? 'VINCULADO'
                                : 'NO VINCULADO'}
                            </strong>
                          </Text>
                          <Text
                            as="span"
                            hidden={
                              siigoShippingItemCode == null ||
                              siigoShippingItemCode == ''
                            }
                          >
                            Codigo: {siigoShippingItemCode}
                          </Text>
                          <Text
                            as="span"
                            hidden={
                              siigoShippingItemCode == null ||
                              siigoShippingItemCode == ''
                            }
                          >
                            {' '}
                            Nombre: {siigoShippingItemName}
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        borderStyle="none"
                        display="flex"
                        flexDirection="row"
                        gap="2"
                      >
                        <Checkbox
                          name="email-costo-de-envio-incluido"
                          label="Incluir costo de envío en factura aunque su monto sea 0"
                          checked={includeShippingCostInvoice}
                          onChange={(event) => {
                            handleChangeIncludeShippingCostInvoice(event);
                          }}
                        />
                      </Box>
                    </Box>
                    <Box borderStyle="none" marginTop="4">
                      <Box
                        borderStyle="none"
                        display="flex"
                        flexDirection="row"
                        gap="2"
                      >
                        <Text as="span">
                          <strong>Vinculación de pagos</strong>
                        </Text>
                        <Tooltip
                          content="Vincule cada medio de pago de Tiendanube con el pago que se reflejará en Siigo.
                          El medio de pago por default se utilizará en caso de no poder concretarse la creación de la factura electrónica
                          con el medio de pago vinculado. Para más información accede al instructivo y visita la sección 'Automatización de facturas electrónicas' "
                        >
                          {<QuestionCircleIcon />}
                        </Tooltip>
                      </Box>
                      <Box borderStyle="none" marginTop="4">
                        <Layout columns="2 - symmetric">
                          <Layout.Section>
                            <Text
                              as="span"
                              textAlign="center"
                              fontSize="base"
                              fontWeight="medium"
                            >
                              Tiendanube
                            </Text>
                            <Box marginTop="2">
                              {paymentsTn ? (
                                paymentsTn.map((payment: PaymentTn) => (
                                  <Box
                                    key={payment.payment_tn_id}
                                    marginBottom="6"
                                  >
                                    <div
                                      key={payment.payment_tn_id}
                                      data-tn-id={payment.payment_tn_id}
                                    >
                                      <Text>{payment.name}</Text>
                                    </div>
                                  </Box>
                                ))
                              ) : (
                                <div></div>
                              )}
                            </Box>
                          </Layout.Section>
                          <Layout.Section>
                            <Text
                              as="span"
                              textAlign="center"
                              fontSize="base"
                              fontWeight="medium"
                            >
                              Siigo
                            </Text>
                            {paymentsTn ? (
                              paymentsTn.map((tn_payment: PaymentTn) => (
                                <Select
                                  appearance="neutral"
                                  id={tn_payment.payment_tn_id}
                                  key={tn_payment.payment_tn_id}
                                  name={tn_payment.name}
                                  onChange={(event) => {
                                    handleConfigPayments(event);
                                  }}
                                >
                                  <Select.Option
                                    label="Seleccionar medio de pago"
                                    value="Seleccionar medio de pago"
                                    selected={configPayments.some((config) => {
                                      if (
                                        tn_payment.payment_tn_id ==
                                          config.payment_tn_id &&
                                        config.payment_siigo_id == null
                                      ) {
                                        console.log(
                                          tn_payment.payment_tn_id +
                                            ' is equal to ' +
                                            config.payment_tn_id +
                                            'and ' +
                                            config.payment_siigo_id +
                                            ' is null',
                                        );
                                        return true;
                                      }
                                      return false;
                                    })}
                                  />
                                  {paymentsSiigo ? (
                                    paymentsSiigo.map(
                                      (siigo_payment: PaymentSiigo) => (
                                        <Select.Option
                                          key={siigo_payment.payment_siigo_id}
                                          value={siigo_payment.payment_siigo_id}
                                          label={siigo_payment.name}
                                          selected={configPayments.some(
                                            (config) => {
                                              if (
                                                config.payment_tn_id ==
                                                  tn_payment.payment_tn_id &&
                                                config.payment_siigo_id ==
                                                  siigo_payment.payment_siigo_id
                                              ) {
                                                return true;
                                              }
                                              return false;
                                            },
                                          )}
                                        ></Select.Option>
                                      ),
                                    )
                                  ) : (
                                    <div></div>
                                  )}
                                </Select>
                              ))
                            ) : (
                              <div></div>
                            )}
                          </Layout.Section>
                        </Layout>
                      </Box>
                    </Box>
                    <Box display="flex" marginTop="4">
                      <Button onClick={saveConfigOrders} appearance="primary">
                        Guardar configuración ordenes
                      </Button>
                    </Box>
                  </Box>
                </Card.Body>
              </Card>
              <Modal onDismiss={onModalSyncClose} open={isModalOpen}>
                <React.Fragment key=".0">
                  <Modal.Header title="ATENCIÓN" />
                  <Modal.Body padding="none">
                    <Text as="span">
                      Inventario seleccionado:{' '}
                      <strong> {inventory?.name}</strong>
                      <p>
                        Si acepta estará enviando todos los productos del
                        Inventario de Siigo a Tiendanube.
                      </p>
                      <p> Requisitos a tener en cuenta:</p>
                      <Box marginLeft="12" gap="4" marginBottom="2">
                        <ul>
                          <li>Todos los productos deben tener SKU cargado</li>
                          <li>
                            No se podrá revertir la acción una vez efectuada
                          </li>
                        </ul>
                      </Box>
                      <p>Además , tener en cuenta que: </p>
                      <p>
                        Los productos se crearán sin fotos y como{' '}
                        <strong>productos independientes</strong> (sin
                        variantes)
                      </p>
                      <Box marginTop="2" marginBottom="2">
                        <Text as="p">
                          La sincronización correrá en segundo plano y el tiempo
                          de sincronización dependerá del tamaño de su catalogo.
                        </Text>
                      </Box>
                      <p>
                        Ante cualquier duda, podrá revisar el instructivo
                        haciendo click aquí, donde se explica con más detalle
                        este apartado.
                      </p>
                    </Text>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={onModalSyncClose} appearance="danger">
                      CANCELAR
                    </Button>
                    <Button onClick={syncProducts} appearance="primary">
                      ACEPTAR
                    </Button>
                  </Modal.Footer>
                </React.Fragment>
              </Modal>
              <Modal onDismiss={onModalSyncClose2} open={isModalOpen2}>
                <React.Fragment key=".0">
                  <Modal.Header title="ATENCIÓN" />
                  <Modal.Body padding="none">
                    <Text as="span">
                      <p>
                        Inventario seleccionado:{' '}
                        <strong> {inventory2?.name}</strong>
                      </p>
                      <p>
                        Impuesto seleccionado: <strong> {taxSync?.name}</strong>
                      </p>
                      <p>
                        Si acepta estará enviando todos los productos de
                        Tiendanube al inventario seleccionado Siigo.{' '}
                      </p>
                      <p> Requisitos a tener en cuenta:</p>
                      <Box marginLeft="12" gap="4">
                        <ul>
                          <li>Todos los productos deben tener SKU cargado</li>
                          <li>
                            Si el producto cuenta con variantes, se crearan como{' '}
                            <strong>productos individuales</strong>
                          </li>
                          <li>
                            No se cargaran con imagenes ya que Siigo no lo
                            admite
                          </li>
                          <li>
                            No se podrá revertir la acción una vez efectuada
                          </li>
                        </ul>
                      </Box>
                      <Box marginTop="2" marginBottom="2">
                        <Text as="p">
                          La sincronización correrá en segundo plano y el tiempo
                          de sincronización dependerá del tamaño de su catalogo.
                        </Text>
                      </Box>
                      <p>
                        Ante cualquier duda, podrá revisar el instructivo
                        haciendo click aquí, donde se explica con más detalle
                        este apartado.
                      </p>
                    </Text>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={onModalSyncClose2} appearance="danger">
                      CANCELAR
                    </Button>
                    <Button onClick={syncProducts2} appearance="primary">
                      ACEPTAR
                    </Button>
                  </Modal.Footer>
                </React.Fragment>
              </Modal>
            </Layout.Section>
            <Layout.Section>
              <Card>
                <Card.Header title="Enviar clientes de Tiendanube a Siigo" />
                <Card.Body>
                  <Box display="flex" justifyContent="center" gap="4">
                    <Button
                      onClick={syncClients}
                      disabled={isButtonDisabled}
                      appearance="primary"
                    >
                      Sincronizar
                    </Button>
                  </Box>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header title="Sincronización automática de stock" />
                <Card.Body>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="4"
                    marginTop="4"
                  >
                    <Text as="span">
                      <strong>
                        {' '}
                        Selecciona el inventario desde donde se sincronizarán
                        stocks
                      </strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="stock-sync-inventory"
                      name="Name"
                      onChange={(event) => {
                        handleChangeInventorySyncStock(event);
                      }}
                    >
                      <Select.Option
                        label="Seleccionar Inventario"
                        selected={
                          inventorySyncStock?.inventory_id ? false : true
                        }
                        value="Seleccionar inventario"
                      />
                      {inventories ? (
                        inventories.map((inventory: Inventory) => (
                          <Select.Option
                            key={inventory.inventory_id}
                            value={inventory.inventory_id}
                            label={inventory.name}
                            selected={
                              inventorySyncStock?.inventory_id ==
                              inventory.inventory_id
                                ? true
                                : false
                            }
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                    <Text as="span">
                      <strong>
                        {' '}
                        Selecciona la bodega desde donde se sincronizarán stocks
                      </strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="stock-sync-bodega"
                      name="Name"
                      onChange={(event) => {
                        handleChangeWarehouseSyncStock(event);
                      }}
                    >
                      <Select.Option
                        label="Seleccionar Bodega"
                        selected={
                          warehouseSyncStock?.warehouse_id ? false : true
                        }
                        value="Seleccionar bodega"
                      />
                      {warehouses ? (
                        warehouses.map((warehouse: Warehouse) => (
                          <Select.Option
                            key={warehouse.warehouse_id}
                            value={warehouse.warehouse_id}
                            label={warehouse.name}
                            selected={
                              warehouseSyncStock?.warehouse_id ==
                              warehouse.warehouse_id
                                ? true
                                : false
                            }
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                    <Box display="flex" justifyContent="center" gap="4">
                      <Button
                        onClick={saveSyncStocksConfig}
                        appearance="primary"
                      >
                        Guardar
                      </Button>
                    </Box>
                  </Box>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header title="Actualizar stock y precio unitario" />
                <Card.Body>
                  <Box display="flex" flexDirection="column" gap="4">
                    <Text as="span">
                      <strong>
                        {' '}
                        Selecciona el inventario y el sku del producto que se
                        desea actualizar en tiendanube
                      </strong>
                    </Text>
                    <Label>Inventario</Label>
                    <Select
                      appearance="neutral"
                      id="inventory-select-update-sync-unitary"
                      name="Name"
                      onChange={(event) => {
                        handleChangeInventorySyncStockUnitary(event);
                      }}
                    >
                      <Select.Option
                        disabled
                        label="Seleccionar inventario"
                        selected
                        value={
                          inventorySyncStockUnitary
                            ? inventorySyncStockUnitary.inventory_id
                            : 'Option default'
                        }
                      />
                      {inventories ? (
                        inventories.map((inventory: Inventory) => (
                          <Select.Option
                            key={inventory.inventory_id}
                            value={inventory.inventory_id}
                            label={inventory.name}
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                    <FormField.Input
                      appendPosition="start"
                      label="SKU"
                      placeholder="Ejemplo: XXX123"
                      onChange={(event) => {
                        handleChangeSKU(event);
                      }}
                    />
                    <Box display="flex" justifyContent="center" gap="4">
                      <Button
                        onClick={updateStockSKU}
                        disabled={buttonDisabledSyncStockUnitary}
                        appearance="primary"
                      >
                        Actualizar
                      </Button>
                    </Box>
                  </Box>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header title="Registros" />
                <Card.Body>
                  <Box display="flex" flexDirection="column" gap="4">
                    <Text as="span"> Registros de acciones efectuadas</Text>
                    <Box display="flex" justifyContent="center" gap="4">
                      <Button onClick={goToLogs} appearance="neutral">
                        Ver registros
                      </Button>
                    </Box>
                  </Box>
                </Card.Body>
              </Card>
            </Layout.Section>
          </Layout>
        </Layout>
      </Page.Body>
    </Page>
  );
};

export default ConfigPage;
